<template>
  <div>

    <Lightbox ref="lightbox"/>

    <v-dialog v-model="dialog" fullscreen persistent scrollable>
      <v-card color="#eef2f6" tile>
        <v-card-title>
          Routing
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-subtitle class="font-weight-medium primary--text">
          {{ animator.name }}
        </v-card-subtitle>

        <v-divider/>
        <v-card-text class="px-lg-15 pa-4">

          <div v-if="!dates.length"
               class="pa-15">
            <v-alert border="top" class="mb-0" prominent text type="warning">
              Veuillez saisir la date de début et de fin de la campagne.
            </v-alert>
          </div>

          <v-chip-group v-else
                        v-model="selectedDateIndex"
                        mandatory
                        next-icon="mdi-arrow-right-bold-circle"
                        prev-icon="mdi-arrow-left-bold-circle"
                        show-arrows>
            <v-chip v-for="(item,index) in dates"
                    :key="index"
                    :value="index"
                    active-class="primary white--text"
                    class="bg-primary-subtle"
                    filter>
              {{ $func.dateFormat(item.date) }}
              <v-icon v-if="item.salepoints.length" color="secondary" right small>mdi-checkbox-blank-circle</v-icon>
              <v-icon v-else color="orange" right small>mdi-checkbox-blank-circle</v-icon>
            </v-chip>
          </v-chip-group>

          <v-card v-if="dates.length"
                  class="rounded-lg shadow">

            <v-card-text>
              <v-row>
                <v-col cols="12" lg="8">

                  <div class="d-flex justify-space-between align-center mb-3">

                    <div class="d-flex align-center">
                      <v-text-field v-model="keyword"
                                    :style="{width : '250px'}"
                                    class="rounded-input"
                                    clearable
                                    dense
                                    hide-details
                                    outlined
                                    placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                                    single-line/>
                    </div>

                    <v-spacer/>

                    <v-btn v-if="Object.keys(filter).length"
                           color="red"
                           text @click="[filter = {},getDatabase()]">
                      <v-icon left>mdi-filter-off</v-icon>
                      Filtre
                    </v-btn>

                    <v-btn
                            color="primary"
                            text
                            @click="$refs.filterDatabaseDialog.open()">
                      <v-icon left>mdi-filter</v-icon>
                      Filtre
                    </v-btn>

                  </div>


                  <v-skeleton-loader v-if="isLoading" type="table"/>

                  <div v-else>
                    <div v-if="salepoints.length">
                      <v-simple-table class="table-border rounded-lg">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Code</th>
                            <th class="text-center">Compte Gifty</th>
                            <th>Magasin</th>
                            <th>N°Téléphone</th>
                            <th>Type</th>
                            <th>Wilaya</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,index) in salepoints"
                              :key="index"
                              :class="dates[selectedDateIndex].salepoints.filter(value => value.id === item.id).length ? 'v-data-table__selected' :''"
                              class="cursor-pointer"
                              @click="dates[selectedDateIndex].salepoints.filter(value => value.id === item.id).length ? inCheckedPos(item) : checkedPos(item)"
                          >
                            <td>

                              <v-btn v-if="dates[selectedDateIndex].salepoints.filter(value => value.id === item.id).length"
                                     color="secondary"
                                     icon
                                     @click0s.stop="inCheckedPos(item)">
                                <v-icon>mdi-checkbox-marked</v-icon>
                              </v-btn>

                              <v-btn v-else icon @click.stop="checkedPos(item)">
                                <v-icon>mdi-checkbox-blank-outline</v-icon>
                              </v-btn>

                            </td>
                            <td>
                              <div class="d-flex text-no-wrap align-center">

                                <v-avatar v-if="item.image" size="38">
                                  <v-img :src="$baseUrl + item.image"
                                         class="zoom-pointer"
                                         @click="$refs.lightbox.open(item.image)"></v-img>
                                </v-avatar>

                                <v-avatar v-else
                                          :class="$func.randomColor()"
                                          class="font-weight-medium"
                                          size="38">
                                  {{ $func.avatarName(item.name) }}
                                </v-avatar>

                                <span class="ml-2">{{ item.name }}</span>
                              </div>
                            </td>
                            <td>
                              <span class="font-weight-medium orange--text">{{ item.code }}</span>
                            </td>
                            <td class="text-center">
                              <v-icon v-if="item.has_gifty_account" color="secondary" size="20">mdi-check-circle</v-icon>
                              <v-icon v-else color="red" size="20">mdi-close-circle</v-icon>
                            </td>
                            <td>{{ item.store_name }}</td>
                            <td>
                              {{ item.phone }}
                            </td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.city }}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-card-actions v-if="total > 10">
                        <v-spacer/>
                        <v-pagination v-if="total>10"
                                      v-model="pagination.current"
                                      :length="pagination.total"
                                      total-visible="5"
                                      @input="onPageChange"
                        ></v-pagination>
                        <v-spacer/>
                      </v-card-actions>
                    </div>
                    <NoResult v-else/>
                  </div>

                </v-col>

                <v-col cols="12" lg="4">

                  <div :style="!$vuetify.breakpoint.mobile ? {marginTop:' 52px'} : ''"
                       class="rounded-lg table-border grey lighten-5">

                    <div v-if="dates[selectedDateIndex]?.salepoints.length">

                      <div class="pa-2">
                        <v-alert border="top" class="fs-14" color="primary" dark dense type="success">
                          Vous pouvez changer l'ordre de pos et choisir le backup.
                        </v-alert>
                      </div>

                      <v-list color="grey lighten-5" dense>

                        <draggable v-model="dates[selectedDateIndex].salepoints"
                                   :options="{animation:300, handle:'.handle'}">

                          <v-list-item v-for="(item,index) in dates[selectedDateIndex].salepoints"
                                       :key="index"
                                       :value="item"
                                       color="primary"
                                       link
                          >

                            <v-list-item-avatar v-if="item.image">
                              <v-img :src="$baseUrl + item.image"
                                     class="zoom-pointer"
                                     @click="$refs.lightbox.open(item.image)"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-avatar v-else
                                                :class="$func.randomColor()"
                                                class="font-weight-medium"
                            >
                              {{ $func.avatarName(item.name) }}
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>{{ item.code }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-switch v-model="item.is_backup"
                                      class="pa-0 ma-0"
                                      color="secondary"
                                      dense
                                      hide-details
                                      label="Backup"/>

                            <v-list-item-action>
                              <v-list-item-action-text>
                                <v-btn :style="{cursor: 'move'}"
                                       class="handle"
                                       icon>
                                  <v-icon>mdi-drag-variant</v-icon>
                                </v-btn>
                                <v-btn color="red" icon @click.stop="inCheckedPos(item)">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-list-item-action-text>
                            </v-list-item-action>

                          </v-list-item>
                        </draggable>

                      </v-list>

                    </div>

                    <div v-else class="d-block">
                      <div class="text-center pa-2">
                        <v-alert border="top" class="fs-14 text-left" color="primary" type="info" dark dense>
                          Aucun point de vente sélectionné !
                          <br>
                          Veuillez en sélectionner au max 3 points de vente.
                        </v-alert>
                        <img :src="require('@/assets/assignment.svg')" alt="" width="180"/>
                      </div>

                    </div>

                  </div>

                </v-col>

              </v-row>

            </v-card-text>
          </v-card>

        </v-card-text>
      </v-card>
    </v-dialog>

    <Lightbox ref="lightbox"/>
    <FilterDatabaseDialog ref="filterDatabaseDialog"
                          @search="[pagination.current = 1,filter = $event,getDatabase()]"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FilterDatabaseDialog from "@/views/database/components/FilterDatabaseDialog.vue";
import draggable from 'vuedraggable'

export default {
    components: {FilterDatabaseDialog, draggable},
    props: {
        dates: Array
    },
    data() {
        return {
            animator: {},
            dialog: false,
            selectedDateIndex: 0,
            /***
             * table pos
             */
            salepoints: [],
            isLoading: false,
            keyword: null,
            total: 0,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {},


        }
    },
    methods: {
        inCheckedPos(item) {
            let index = this.dates[this.selectedDateIndex].salepoints.findIndex(value => value.id === item.id)
            this.dates[this.selectedDateIndex].salepoints.splice(index, 1)
        },
        checkedPos(item) {
            if (this.dates[this.selectedDateIndex].salepoints
                && this.dates[this.selectedDateIndex].salepoints.length < 3){
                this.dates[this.selectedDateIndex].salepoints.push({
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    has_gifty_account: item.has_gifty_account,
                    photo: item.photo,
                    is_backup: false,
                })
            }else {
                this.$warningMessage = "Vous ne pouvez pas en choisir plus de trois points de vente."
            }

        },
        open(animator) {
            /**
             * Get All pos
             */
            this.getDatabase()
            this.selectedDateIndex = 0
            this.animator = animator
            this.dialog = true
        },
        getDatabase() {
            this.isLoading = true
            HTTP.get('/database?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: 10,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false

                /**
                 * pars data
                 */
                let arr = res.data.data.data.map((salepoint) => {

                    return {
                        id: salepoint.id,
                        name: salepoint.name,
                        photo: salepoint.photo,
                        phone: salepoint.phone,
                        store_name: salepoint.store_name,
                        has_gifty_account: salepoint.has_gifty_account,
                        code: salepoint.code,
                        city: salepoint.city,
                        type: salepoint.type,
                    }
                })
                this.salepoints = JSON.parse(JSON.stringify(arr))

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getDatabase();
        },
    },
    watch: {
        keyword() {
            this.getDatabase();
        }
    },
}
</script>

<style scoped>

</style>